import React, { Fragment } from 'react';
import cn from 'classnames';
import styles from '../index.module.scss';
import {
  Button,
  ButtonProps,
  ButtonTypeEnum,
  IconEnum,
} from '../../../common/components/ui-elements';
import { colorPrimary, toCamelCase } from '~/common/utils';
import { useSpring, animated } from 'react-spring';
import { IHeroCardProps } from '../store';
import { SvgIcon } from '~/common/components/ui-elements/svg-icon';
import { mediaAnimationProps } from '../common';

const HeroCardCTAs = React.memo(
  ({
    contentCTAs,
    visible,
    isMobile,
    id,
    contentCTAsAlignment,
    contentCTAsStackable,
  }: IHeroCardProps) => {
    const animateStyle = useSpring(mediaAnimationProps(visible));
    const _contentCTAs = contentCTAs?.filter(
      (x) => !(x.ctaHidden || (isMobile && x.ctaHideInMobile))
    );

    const mapButtonSizeStyle = (cta: string) => {
      switch (cta.toLowerCase()) {
        case 'small':
          return { minWidth: 'calc(25% - 10px)' };
        case 'medium':
          return { minWidth: 'calc(50% - 10px)' };
        case 'large':
          return { minWidth: 'calc(75% - 10px)' };
        default:
          return {};
      }
    };

    const mapCTAsAlignment = (alignment: string): React.CSSProperties => {
      switch (alignment.toLowerCase()) {
        case 'center':
          return { alignItems: 'center', justifyContent: 'center' };
        case 'right':
          return { alignItems: 'end', justifyContent: 'end' };
        case 'left':
        default:
          return { alignItems: 'start', justifyContent: 'start' };
      }
    };

    return (
      <>
        {_contentCTAs && _contentCTAs.length > 0 && (
          <animated.div
            className={styles.HeroCardCTAs}
            style={{
              ...animateStyle,
              ...(contentCTAsStackable
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    ...mapCTAsAlignment(contentCTAsAlignment || ''),
                  }
                : contentCTAsAlignment
                ? {
                    textAlign: contentCTAsAlignment,
                  }
                : {}),
            }}
          >
            {_contentCTAs?.map((cta, idx) => {
              const _style: React.CSSProperties = {
                ...(cta.ctaColor ? { color: `#${cta.ctaColor}` } : {}),
                ...(cta.ctaColor ? { borderColor: `#${cta.ctaColor}` } : {}),
                ...mapButtonSizeStyle(!isMobile ? cta.ctaButtonSize || '' : ''),
              };
              const buttonProps: ButtonProps = {
                type: toCamelCase(
                  cta.ctaButtonType
                ) as keyof typeof ButtonTypeEnum,
                href: cta.ctaUrl?.url,
                target: cta.ctaUrl?.target || '_self',
                style: _style,
                buttonWidth: cta.ctaFullWidth,
                buttonSize:
                  (cta.ctaButtonSize?.toLowerCase() as ButtonProps['buttonSize']) ||
                  'medium',
              };
              if (cta.ctaUrl?.url) {
                if (cta.ctaUrl?.url.includes(window.location.pathname)) {
                  buttonProps.onClick = () => {
                    window.localStorage.setItem('prevSectionId', `${id}`);
                  };
                }
              }

              const renderCTA = () => (
                <Button
                  key={idx}
                  className={cn(
                    styles.HeroCardCTA,
                    styles[`HeroCardCTA${buttonProps.type}`],
                    {
                      [styles.HeroCardCTAColor]: !!cta.ctaColor,
                    }
                  )}
                  {...buttonProps}
                >
                  {cta.ctaIcon && cta.ctaIcon !== 'none' && (
                    <SvgIcon
                      type={cta.ctaIcon as keyof typeof IconEnum}
                      color={`#${colorPrimary}`}
                      size={1.2}
                      strokeWidth={2}
                    />
                  )}
                  <span
                    className={styles.HeroCardCTAText}
                    style={
                      buttonProps.type === 'text'
                        ? { color: `#${colorPrimary}` }
                        : {}
                    }
                  >
                    {cta.ctaUrl?.name || ''}
                  </span>
                </Button>
              );

              return (
                <Fragment key={idx}>
                  {contentCTAsStackable ? (
                    <div
                      className={styles.HeroCardCTAContainer}
                      style={{
                        ...(cta.ctaButtonAlignment || contentCTAsAlignment
                          ? {
                              textAlign:
                                cta.ctaButtonAlignment || contentCTAsAlignment,
                            }
                          : {}),
                      }}
                    >
                      {renderCTA()}
                    </div>
                  ) : (
                    renderCTA()
                  )}
                </Fragment>
              );
            })}
          </animated.div>
        )}
      </>
    );
  }
);

export default HeroCardCTAs;
